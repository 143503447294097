import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import BildHeader from "../images/schwarz-optik/header.png"
import BildOben from "../images/schwarz-optik/bildoben.png"
import BildUnten from "../images/schwarz-optik/bildunten.png"
import Termin from "../components/Termin"

const StyledButtonWrapper = styled.div`
  margin-top: 90px;
  margin-bottom: 100px;
`

const StyledButton = styled.a`
  background-color: white;
  color: $mainColor;
  font-family: "Helvetica Neue LT W05_87 Hv Cn", sans-serif;
  font-size: 28px;
  text-decoration: none;
  padding: 14px 28px;

  &:hover {
    color: black;
    text-decoration: none;
  }
`

const SchwarzOptik = () => {
  const siteTitle = "SCHWARZ Optik"

  return (
    <Layout title={siteTitle}>
      <SEO title={"Seit 1992 in Heiden"} />

      <section className="wrapper">
        <br />
        <br />
        <br />
        <br />
        <br />
      </section>
      <div className="greenVer">
        <Termin />
      </div>
      <div className="greenVer">
        <section
          className="wrapper content"
          style={{ backgroundColor: "#00aa9b" }}
        >
          <h1>SCHWARZ Optik</h1>
          <h2 className="contentSubtitle">Die Profis für gesundes Sehen</h2>
          <div className="contentColumns">
            <div className="contentColumn">
              <div className="imageCollection">
                <div className="imageRow">
                  <img src={BildOben} />
                </div>
                <div className="imageRow">
                  <img src={BildUnten} />
                </div>
              </div>
            </div>
            <div className="contentColumn">
              <h2>Wir freuen uns auf Ihren Besuch</h2>
              <p>
                Viele Stammkunden aus dem Appenzeller Vorderland und dem
                St.Galler Rheintal schätzen seit 1992 die Dienstleistungen der
                SCHWARZ Optik. Gerne unterstützen wir auch Besucher*innen und
                Feriengäste unserer schönen Region wenn kleinere und grössere
                Probleme mit Sehhilfen anstehen. Herzlich willkommen!
              </p>
            </div>
          </div>
          <div className="wrapper centered">
            <StyledButtonWrapper>
              <StyledButton
                target="_blank"
                href="https://goo.gl/maps/kis7zBhzDN6Vjbuu7"
              >
                So finden Sie zu uns…
              </StyledButton>
            </StyledButtonWrapper>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default SchwarzOptik
